import { FC, useState, useEffect } from 'react';
import CMS from "utils/cms";
import Seo from 'components/cms/seo';
import dynamic from 'next/dynamic';
import LuckyTicket from "themes/default/cms-views/luckyTicket";
// const FooterInfo = dynamic(() => import('themes/default/cms-views/footer'));
// const Promotions = dynamic(() => import('themes/default/cms-views/promotions/index'));
// const ThemesSliderNuSuspense = dynamic(() => import('themes/default/cms-views/themesSlider/themesSlider'));
// const IdPromotions = dynamic(() => import('themes/default/cms-views/idPromotion'));
// const FullWidthCarousel = dynamic(() => import('themes/default/cms-views/full_with_carousel'));
// const CategoryGamePage = dynamic(() => import('themes/default/cms-views/pageTypeCategoryGames'));
// const ThemesPage = dynamic(() => import('themes/default/cms-views/themesPage'));
// const AllProviders = dynamic(() => import('themes/default/cms-views/allProviders'));
// const RtpGamesCarousels = dynamic(() => import('themes/default/cms-views/rtp_live_games'));
// const WinnersProloader = dynamic(() => import('themes/default/cms-views/winners_slider/preloader'));
// const LiveJackpots = dynamic(() => import('themes/default/cms-views/JackpotsLive'));
// const PageTypeInformation = dynamic(() => import('themes/default/cms-views/page_type_information'));
// const CategoriesButtonsSlider = dynamic(() => import('themes/default/cms-views/categories_buttons_slider'));
// const BonusConditions = dynamic(() => import('components/cms/bonusConditions'));
// const ProvidersSlider = dynamic(() => import('themes/default/cms-views/providersSlider'));
// const BannerGoToAction = dynamic(() => import('themes/default/cms-views/bannerGoToAction'));
// const GameBanner = dynamic(() => import('themes/default/cms-views/gameBanner'));
// const TopGamesSlider = dynamic(() => import('themes/default/cms-views/topGamesSlider'));
// const CategoryGamesSlider = dynamic(() => import('themes/default/cms-views/categoryGamesSlider/categoryGamesSlider'));
// const LandingPage = dynamic(() => import('themes/default/cms-views/landing_page'));
// const SeoBox = dynamic(() => import('components/cms/seoBox'));
// const VipQuest = dynamic(() => import('./vipQuest'));
// const SmarticoWidgetPage = dynamic(() => import('themes/default/cms-views/smarticoWidgetPage'));

import FooterInfo from 'themes/default/cms-views/footer';
import Promotions from 'themes/default/cms-views/promotions/index';
import ThemesSliderNuSuspense from 'themes/default/cms-views/themesSlider/themesSlider';
import IdPromotions from 'themes/default/cms-views/idPromotion';
import FullWidthCarousel from 'themes/default/cms-views/full_with_carousel';
import CategoryGamePage from 'themes/default/cms-views/pageTypeCategoryGames';
import ThemesPage from 'themes/default/cms-views/themesPage';
import AllProviders from 'themes/default/cms-views/allProviders';
import RtpGamesCarousels from 'themes/default/cms-views/rtp_live_games';
import WinnersProloader from 'themes/default/cms-views/winners_slider/preloader';
import LiveJackpots from 'themes/default/cms-views/JackpotsLive';
import PageTypeInformation from 'themes/default/cms-views/page_type_information';
import CategoriesButtonsSlider from 'themes/default/cms-views/categories_buttons_slider';
import BonusConditions from 'components/cms/bonusConditions';
import ProvidersSlider from 'themes/default/cms-views/providersSlider';
import BannerGoToAction from 'themes/default/cms-views/bannerGoToAction';
import GameBanner from 'themes/default/cms-views/gameBanner';
import TopGamesSlider from 'themes/default/cms-views/topGamesSlider';
import CategoryGamesSlider from 'themes/default/cms-views/categoryGamesSlider/categoryGamesSlider';
import LandingPage from 'themes/default/cms-views/landing_page';
import SeoBox from 'components/cms/seoBox';
import VipQuest from './vipQuest';
import SmarticoWidgetPage from 'themes/default/cms-views/smarticoWidgetPage';
import GetAppBlock from "@/template/cms-views/getAppBlock";



interface SomeComponentProps {
    blocks: Array<any>;
    api_id:string;
    noSuspense?:boolean;
    
}

const BuilderView: FC<SomeComponentProps> = ({blocks, api_id, noSuspense }) => {

    let renderFirstCategoriesSlider:null | number = null;

    const [isLoading, setIsLoading] = useState(true);
    const [showWinnersSlider, setShowWinnersSlider] = useState(false);

    const WinnersSlider = dynamic(() => import('./winners_slider'), {
        loading: () => <WinnersProloader />,
        ssr: false
    });

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
            setShowWinnersSlider(true);
        }, 700)
    }, [])
    
    return (
        <>

            {blocks?.map((block, i) => {
                switch (block['type']) {

                    case CMS.LIVE_JACKPOTS:
                        return (
                            <LiveJackpots key={`${i}-${CMS.LIVE_JACKPOTS}`} json={block} index={i} />
                        )

                    case CMS.LIVE_RTP_GAMES_CAROUSEL:
                        return (
                            <RtpGamesCarousels key={`${i}-${CMS.LIVE_RTP_GAMES_CAROUSEL}`} json={block} index={i} />
                        )

                    case CMS.BLOCK_FULL_WIDTH_CAROUSEL:

                        return (
                            <FullWidthCarousel key={`${i}-${CMS.BLOCK_FULL_WIDTH_CAROUSEL}`} first={(i === 0 || i === 1)} api_id={api_id}  json={block} />
                        )

                    case CMS.BLOCK_TOP_GAMES_SLIDER:
                        return (
                            <TopGamesSlider json={block} key={`${i}-${CMS.BLOCK_TOP_GAMES_SLIDER}`} index={i} />
                        )

                    case CMS.BLOCK_CATEGORY_GAMES_SLIDER:
                        if (renderFirstCategoriesSlider === null) renderFirstCategoriesSlider = i;

                        return (
                            <CategoryGamesSlider key={`${i}-${CMS.BLOCK_CATEGORY_GAMES_SLIDER}`} disableLazyImage={renderFirstCategoriesSlider === i} api_id={api_id} index={i} json={block} />
                        )

                    case CMS.BLOCK_THEMES_SLIDER:
                        return <ThemesSliderNuSuspense api_id={api_id} json={block} key={`${i}-${CMS.BLOCK_THEMES_SLIDER}`} index={i} />


                    case CMS.BLOCK_BLOCK_PAGES:
                        return <PageTypeInformation key={`${i}-${CMS.BLOCK_BLOCK_PAGES}`} json={block} />

                    case CMS.BLOCK_THEMES_PAGE:
                        return <ThemesPage key={`${i}-${CMS.BLOCK_THEMES_PAGE}`} json={block} api_id={api_id} />

                    case CMS.BLOCK_CATEGORIES_BUTTONS_SLIDER:
                        return <CategoriesButtonsSlider second={(i === 2 || i === 3)} api_id={api_id} key={`${i}-${CMS.BLOCK_CATEGORIES_BUTTONS_SLIDER}`} json={block}  />

                    case CMS.BLOCK_BONUS_CONDITIONS:
                        return <BonusConditions  key={`${i}-${CMS.BLOCK_BONUS_CONDITIONS}`} json={block}/>

                    case CMS.BLOCK_WINNERS_SLIDER:
                        return (
                            <div key={`${i}-${CMS.BLOCK_WINNERS_SLIDER}`}>
                                {isLoading && <WinnersProloader json={block} />}
                                {showWinnersSlider && <WinnersSlider json={block}/>}
                            </div>
                        )

                    case CMS.BLOCK_PROVIDERS_SLIDER:
                        return (
                            <ProvidersSlider key={`${i}-${CMS.BLOCK_PROVIDERS_SLIDER}`} json={block} index={i} />
                        )

                    case CMS.BLOCK_BANNER_GO_TO_ACTION:
                        return <BannerGoToAction api_id={api_id} key={`${i}-${CMS.BLOCK_BANNER_GO_TO_ACTION}`} json={block} />

                    case CMS.BLOCK_GAME_BANNER:
                        return <GameBanner api_id={api_id}  key={`${i}-${CMS.BLOCK_GAME_BANNER}`} json={block} />

                    case CMS.BLOCK_FOOTER:
                        return <FooterInfo key={`${i}-${CMS.BLOCK_FOOTER}`} api_id={api_id} json={block} />

                    case CMS.BLOCK_PROMOTIONS:
                        return <Promotions key={`${i}-${CMS.BLOCK_PROMOTIONS}`} json={block} api_id={api_id} />

                    case CMS.BLOCK_SEO:
                        return <Seo api_id={api_id} key={`${i}-${CMS.BLOCK_SEO}`} json={block}  />

                    case CMS.BLOCK_DETAIL_PROMOTION:
                        return <IdPromotions api_id={api_id} key={`${i}-${CMS.BLOCK_DETAIL_PROMOTION}`} json={block} />

                    case CMS.BLOCK_CATEGORY_GAMES_PAGE:
                        return <CategoryGamePage key={`${i}-${api_id}-${CMS.BLOCK_CATEGORY_GAMES_PAGE}`} json={block} api_id={api_id} />

                    case CMS.BLOCK_ALL_PROVIDERS:
                        return <AllProviders key={`${i}-${CMS.BLOCK_ALL_PROVIDERS}`} json={block} api_id={api_id} />

                    case CMS.BLOCK_LANDING_PAGE:
                        return <LandingPage key={`${i}-${CMS.BLOCK_LANDING_PAGE}`} json={block} api_id={api_id}  />

                    case CMS.BLOCK_SEO_BOX:
                        return <SeoBox key={`${i}-${CMS.BLOCK_SEO_BOX}`} json={block} api_id={api_id}/>
                    
                    case CMS.BLOCK_VIP_QUEST:
                        return <VipQuest key={`${i}-${CMS.BLOCK_VIP_QUEST}`} json={block} api_id={api_id}/>
                    
                    case CMS.BLOCK_LUCKY_TICKET:
                        return <LuckyTicket key={`${i}-${CMS.BLOCK_LUCKY_TICKET}`} json={block} api_id={api_id}/>
                    
                    
                    case CMS.BLOCK_SMARTICO_WIDGET_PAGE:
                        return <SmarticoWidgetPage key={`${i}-${CMS.BLOCK_SMARTICO_WIDGET_PAGE}`} json={block} api_id={api_id}/>
                    
                    

                    

                    default: return null;
                }
            })}
        </>
    );
};

export default BuilderView;